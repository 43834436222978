"use client";
import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import logo from "../../public/logo.svg"; // Replace with your logo path
import { motion, AnimatePresence } from "framer-motion";
import ShimmerButton from "../ui/shimmer-button"; // Importing ShimmerButton
import { useRouter } from "next/navigation"; // Importing useRouter

const Navbar = () => {
  const router = useRouter(); // Initialize router
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // Reset submenu when closing the entire menu
  };

  return (
    <header className="fixed inset-x-0 top-3 z-50 w-full before:absolute before:inset-0 before:mx-2 before:max-w-[66rem] before:rounded-[26px] before:bg-[#000000]/90 before:backdrop-blur-md before:lg:mx-auto">
      <nav className="relative mx-2 w-full max-w-[66rem] px-5 py-2 md:flex md:items-center md:justify-between lg:mx-auto">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link href="/">
            <div className="flex items-center space-x-2 text-xl font-semibold text-white">
              <Image src={logo} alt="Logo" width={160} height={40} />
            </div>
          </Link>

          {/* Hamburger Menu Button for Mobile */}
          <button
            className="flex items-center text-white focus:outline-none md:hidden"
            onClick={toggleMenu}
          >
            <svg
              className={`size-6 ${isOpen ? "hidden" : "block"}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
            <svg
              className={`size-6 ${isOpen ? "block" : "hidden"}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Mobile Drop-Down Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="mt-4 overflow-hidden rounded-[26px] p-4 md:hidden"
            >
              <nav className="flex flex-col space-y-2">
                <Link
                  href="/"
                  onClick={toggleMenu}
                  className="text-white hover:text-neutral-300"
                >
                  Home
                </Link>
                <Link
                  href="/projects"
                  onClick={toggleMenu}
                  className="text-white hover:text-neutral-300"
                >
                  Projects
                </Link>
                <Link
                  href="/faq"
                  onClick={toggleMenu}
                  className="mb-4 text-white hover:text-neutral-300"
                >
                  FAQs
                </Link>

                {/* Dropdown for About 
                <div className="relative">
                  <button
                    className="w-full text-left text-white hover:text-neutral-300 flex items-center justify-between"
                    onClick={toggleSubmenu}
                  >
                    About <FaChevronDown />
                  </button>
                  <AnimatePresence>
                    {isSubmenuOpen && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 mt-2"
                      >
                        <Link
                          href="/about"
                          onClick={toggleMenu}
                          className="block py-2 text-white hover:text-neutral-300"
                        >
                          About
                        </Link>
                        <Link
                          href="/downloads"
                          onClick={toggleMenu}
                          className="block py-2 text-white hover:text-neutral-300"
                        >
                          Downloads
                        </Link>
                        <Link
                          href="/team"
                          onClick={toggleMenu}
                          className="block py-2 text-white hover:text-neutral-300"
                        >
                          Team Account
                        </Link>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div> */}

                {/* Mobile Sign In Button */}
                <ShimmerButton
                  shimmerColor="#4ade80"
                  shimmerSize="0.05em"
                  shimmerDuration="3s"
                  borderRadius="50px"
                  background="rgba(0, 0, 0, 0.8)"
                  className="mt-4"
                  onClick={() =>
                    router.push(
                      "https://app.nodesforest.com/auth/sign-in/default",
                    )
                  }
                >
                  Sign In
                </ShimmerButton>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Desktop Menu Links */}
        <div className="hidden md:flex md:items-center md:space-x-6">
          <Link href="/" className="text-white hover:text-neutral-300 md:py-4">
            Home
          </Link>
          <Link
            href="/projects"
            className="text-white hover:text-neutral-300 md:py-4"
          >
            Projects
          </Link>
          <Link
            href="/faq"
            className="text-white hover:text-neutral-300 md:py-4 md:pr-60 lg:pr-72"
          >
            FAQs
          </Link>

          {/* Desktop Sign In Button */}
          <ShimmerButton
            shimmerColor="#4ade80"
            shimmerSize="0.05em"
            shimmerDuration="3s"
            borderRadius="50px"
            background="rgba(0, 0, 0, 0.8)"
            className="ml-6"
            onClick={() =>
              router.push("https://app.nodesforest.com/auth/sign-in/default")
            }
          >
            Sign In
          </ShimmerButton>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
